import { haversineDistance } from '@/lib/calculateDistance';
import { GeneralRawDealerType } from '@/lib/schema/dealer/types';
import { validateUSZipCode } from '@/lib/validators';
import { FilterState } from '@/types/filters';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

interface ProductListingLinkFooterProps {
  dealerInfo: GeneralRawDealerType;
  isColumnLayout?: boolean;
  filters?: FilterState;
}

export const ProductListingLinkFooter: React.FC<
  ProductListingLinkFooterProps
> = ({ dealerInfo, filters, isColumnLayout }) => {
  const [distance, setDistance] = useState<number | null>(0);

  useEffect(() => {
    const getDistance = async () => {
      const response = await fetch(
        `/api/distance?zipCode1=${filters?.fields.postal}&zipCode2=${dealerInfo.PostalCode}`
      );
      if (!response.ok) {
        setDistance(null);
      }
      const data = await response.json();
      const { coords1, coords2 } = data;
      const distanceInMiles = haversineDistance(coords1, coords2);
      setDistance(distanceInMiles);
    };
    if (
      filters?.fields.postal &&
      validateUSZipCode(filters?.fields.postal) &&
      filters.shouldRefetch
    ) {
      getDistance();
    }
  }, [dealerInfo, filters]);

  return (
    <div
      className={`flex w-full items-center ${isColumnLayout ? 'justify-center' : 'justify-start'} gap-xl px-xl py-l`}
    >
      <div
        className={`${
          dealerInfo?.Dealership ? 'flex' : 'w-full'
        } items-center justify-end gap-xs whitespace-nowrap text-microLight text-neutral-800`}
      >
        <MapPinIcon height={16} width={16} />
        {dealerInfo.City}, {dealerInfo.State}{' '}
        {distance ? `(${Math.round(distance)} mi)` : ''}
      </div>
    </div>
  );
};
