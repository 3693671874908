import { ListingEmailInfo, MinimalGenerateLeadRequest } from '../types/leads';
import { getPublicIpAddress } from './getPublicIpAddress';
import { DealerType } from './schema/dealer/types';
import { JDPowerIncentive } from './schema/incentives/types';
import {
  VehicleListingType,
  VehicleType,
  VehicleTypeWithTrimSpecs,
} from './schema/inventory/types';
import { CognitoUser } from './schema/user/types';

export interface DealerMessageBodyType {
  product: VehicleTypeWithTrimSpecs | VehicleListingType | VehicleType;
  dealer: DealerType | null;
  user?: CognitoUser | null;
  localRebates: JDPowerIncentive[];
  stateRebates: JDPowerIncentive[];
  estimatedFuelSavingsPerYear?: number;
  body?: MinimalGenerateLeadRequest;
}

export const createDealerMessageBody = async ({
  product,
  dealer,
  user,
  localRebates = [],
  stateRebates = [],
  estimatedFuelSavingsPerYear = 0,
  body,
}: DealerMessageBodyType) => {
  const ipAddress = await getPublicIpAddress();

  let drivetrain = 'N/A';
  if (product.drivetrain) {
    drivetrain = product.drivetrain;
  } else if (
    'trimSpecs' in product &&
    product.trimSpecs.highlights.Drive_Type
  ) {
    drivetrain = product.trimSpecs.highlights.Drive_Type;
  }
  const federalTaxCredit = product.calculatedIncentives?.Federal || 0;

  const parsedLocalAndStateIncentives: { name: string; value: number }[] = [];
  const pointOfSaleRebates: { name: string; value: number }[] = [];
  const afterwardsRebates: { name: string; value: number }[] = [];
  const rebates = [...localRebates, ...stateRebates];
  rebates.forEach((incentive) => {
    if (incentive.max_amount && incentive.max_amount > 0) {
      const reducedIncentive = {
        name: incentive.name,
        value: incentive.max_amount || 0,
      };
      if (incentive.point_of_sale) {
        pointOfSaleRebates.push(reducedIncentive);
      } else {
        afterwardsRebates.push(reducedIncentive);
      }
      parsedLocalAndStateIncentives.push(reducedIncentive);
    }
  });

  const totalSavings =
    parsedLocalAndStateIncentives.reduce((acc, incentive) => {
      return acc + incentive.value;
    }, 0) +
    estimatedFuelSavingsPerYear +
    federalTaxCredit;

  const baseMsrp = product.price.value
    ? product.price.value
    : 'Contact dealer for price';
  const totalMsrp = product.price.value
    ? product.price.value - totalSavings
    : 'Contact dealer for price';

  const messageBody: ListingEmailInfo = {
    ...body,
    full_name: user?.fullName,
    phone: body?.phone ?? '',
    customer_email: body?.customer_email || user?.email,
    message:
      body?.message ||
      `Hi, is the ${product.year} ${product.make} ${product.model} still available?`,
    listing_url: window.location.href,
    listing_id: product.listingId,
    stock_number: product?.stockNumber,
    // additional info required for customer email
    additional_info: {
      dealership: dealer?.name,
      dealership_address: dealer?.address,
      dealership_postal_code: dealer?.postalCode,
      dealership_city: dealer?.city,
      dealership_province: dealer?.province,
      vin: product?.vin,
      listing_id: product?.listingId,
      stockNumber: product?.stockNumber,
      modelImgUrl: product.images[0],
      year: product.year,
      make: product.make,
      model: product.model,
      trim: product.trim,
      base_msrp: baseMsrp,
      total_savings: totalSavings,
      total_msrp: totalMsrp,
      mileage: product.mileage,
      range: product.rangeInfo,
      ip_address: ipAddress,
      fuel_type: product.fuelType || 'N/A',
      drivetrain,
      federal_tax_credit: federalTaxCredit,
      point_of_sale_rebates: pointOfSaleRebates || [],
      afterwards_rebates: afterwardsRebates || [],
      est_fuel_savings: estimatedFuelSavingsPerYear || 0,
    },
  };
  return messageBody;
};
