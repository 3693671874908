/**
 * Converts a value in miles to kilometers, rounded to the nearest kilometer.
 * This is done because we show the distance used to filter products as a path parameter, and it breaks if we show a decimal.
 */
export const milesToNearestKilometer = (miles: string): string =>
  String(Math.round(parseFloat(miles) * 1.609344));

export const kilometersToMile = (km: string): string =>
  String((parseFloat(km) / 1.609344).toFixed(0));

export const kilometersToNearestMile = (km: string): string =>
  String(Math.round(parseFloat(km) / 1.609344));
