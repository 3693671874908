import trim from 'lodash/trim';
import { DealerType } from './schema/dealer/types';
import { RawVehicleType, VehicleListingModel } from './schema/inventory/types';
import { filterUntrustedList } from './utils';

const parseDealer = (
  rawVehicle: RawVehicleType | VehicleListingModel
): DealerType | null => {
  if (!rawVehicle || !rawVehicle?.listing) {
    return null;
  }
  const listing = Array.isArray(rawVehicle.listing)
    ? rawVehicle.listing[0]
    : rawVehicle?.listing;

  const [defaultCity, defaultProvince] = (listing?.City ?? ',').split(',');

  return {
    dealerID: listing?.DealerId || '',
    name: listing?.Dealership ?? '',
    makes: filterUntrustedList(listing?.Makes) ?? [],
    groupName: listing?.DealerGroupName ?? '',
    address: trim(`${listing?.Address ?? ''}`, ','),
    city: defaultCity,
    province: listing?.State || defaultProvince || 'N/A',
    postalCode: listing?.Postal ?? '',
    phone: listing?.Phone ?? '',
    email: filterUntrustedList(listing?.Email) ?? [''],
  };
};

export default parseDealer;
