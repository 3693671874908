import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { Component } from '@/types/interfaces';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export interface ClientPortalProps extends Component {
  open: boolean;
  selector?: string;
  closeFn?: () => void;
}

const ClientPortal: React.FC<ClientPortalProps> = ({
  className = '',
  open,
  selector = 'body #__next > main',
  closeFn,
  children,
}) => {
  const [mounted, setMounted] = React.useState(false);
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    ref.current = (
      selector ? document.querySelector(selector) : document.body
    ) as HTMLElement;
    setMounted(true);
  }, [selector]);

  useEffect(() => {
    if (open) {
      document.documentElement.classList.add('overflow-hidden');
      return () => {
        document.documentElement.classList.remove('overflow-hidden');
      };
    }
    return () => {};
  }, [open]);

  return mounted && open
    ? createPortal(
        <div
          className={`fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-neutralsGrey-700 bg-opacity-50 backdrop-blur-[2px]`}
          onClick={(evt) => {
            evt.stopPropagation();
            if (closeFn) {
              closeFn();
            }
          }}
        >
          <div
            className={`relative max-h-[90vh] w-[90%] overflow-auto bg-transparent shadow-[4px_4px_30px_rgba(44,72,66,0.3)] ${className}`}
            onClick={(evt) => {
              evt.stopPropagation();
            }}
          >
            <div
              className="absolute right-[24px] top-[24px]"
              role="button"
              onClick={closeFn}
            >
              <Icon strokeColor="#010044" iconId={IconIds.Cross} />
            </div>
            {children}
          </div>
        </div>,
        ref.current as HTMLElement
      )
    : null;
};

export default ClientPortal;
