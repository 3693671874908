import { ValueWithUnit } from '@/types';
import { RawVehicleType, VehicleListingModel } from './schema/inventory/types';
import { Range } from './schema/shared/types';

export const parseRange = (
  rawVehicle: RawVehicleType | VehicleListingModel
): Range => {
  const rangeToUse: ValueWithUnit = {
    value:
      rawVehicle?.RangeHybridElectric?.value ??
      rawVehicle?.RangeAllElectric?.value ??
      0,
    unit:
      rawVehicle?.RangeHybridElectric?.unit ??
      rawVehicle?.RangeAllElectric?.unit ??
      'm',
  };

  const { value, unit } = rangeToUse;

  return {
    value: Number(value),
    unit: unit || 'm',
  };
};

export default parseRange;
