import { ListingEmailInfo } from '@/types/leads';

export const contactDealer = async (
  body: ListingEmailInfo
): Promise<Response> => {
  const response = await fetch('/api/message_dealer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response;
};
