import { RawValueWithUnit, ValueWithUnit } from '@/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const filterUntrustedList = <T>(
  x: (T | undefined)[] | undefined
): T[] => {
  if (!x) {
    return [];
  }
  return x.filter((n): n is T => n !== undefined);
};

export const parseRawValueWithUnit = (
  raw?: RawValueWithUnit
): ValueWithUnit => {
  if (!raw) {
    return { value: 'N/A' };
  }
  return { value: raw.Value.toLocaleString(), unit: raw?.Unit };
};

export const encodeSpecialCharacters = (inputString: string) => {
  // Define a mapping of special characters to HTML entities
  const ENTITY_MAP: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&apos;',
    '’': '&#8217;',
    // Add more mappings as needed
  };

  // Replace each special character with its HTML entity
  return inputString.replace(/[&<>"'’]/g, (match) => {
    return ENTITY_MAP[match];
  });
};

export const formatDate = (
  date: Date,
  variant: 'numeric' | 'short',
  location?: string
): string => {
  const format: Intl.DateTimeFormatOptions =
    variant === 'numeric'
      ? {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: location,
          timeZoneName: 'short',
          hour12: false,
        }
      : {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        };

  const dateFormatter = new Intl.DateTimeFormat('en-US', format);

  return dateFormatter.format(date);
};
