export enum Direction {
  Left = 'left',
  Right = 'right',
}

export enum FuelTypes {
  Electric = 'Electric',
  Hybrid = 'Hybrid',
  Gasoline = 'Gasoline',
  Flex = 'Flex',
  Premium = 'Premium',
  Diesel = 'Diesel',
  Unknown = 'Unknown',
}

export enum Status {
  Default,
  Success,
  Error,
  Support,
}

export enum FormErrors {
  Clear,
  Empty,
  INVALID_PHONE,
  INVALID_EMAIL,
  MAXIMUM_LENGTH,
  INVALID_DEALER_LICENSE,
  INVALID_EXPIRATION_DATE,
}

export const isFormValid = (errors: Record<string, FormErrors>): boolean =>
  Object.values(errors).every((error) => error === FormErrors.Clear);
