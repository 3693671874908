import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { showToast } from '@/lib/toast';
import { contactDealer } from '@/services/dealer';
import { ListingEmailInfo } from '@/types/leads';
import { useRouter } from 'next/compat/router';
import { useCallback } from 'react';

export const useContactDealer = () => {
  const router = useRouter();
  const { handleActionTracking } = useAnalytics();

  const messageDealer = useCallback(
    async ({
      body,
      onSuccess,
    }: {
      body: ListingEmailInfo;
      onSuccess?: () => Promise<void>;
    }) => {
      handleActionTracking(GOOGLE_TRACK_INFO.sendMessageButton);
      const response = await contactDealer(body);
      if (response.ok) {
        showToast('Sent! the dealer will be in touch soon.', {
          type: 'success',
          onClick: () => router?.push('/my-evs'),
        });
        handleActionTracking(GOOGLE_TRACK_INFO.sendMessageSuccess);
        if (onSuccess) {
          await onSuccess();
        }
      } else {
        showToast(
          'There was a problem contacting the dealer. Please try again.',
          { type: 'error' }
        );
        handleActionTracking(GOOGLE_TRACK_INFO.sendMessageFail);
      }
    },
    [router, handleActionTracking]
  );

  return { contactDealer: messageDealer };
};
