import { useMemo } from 'react';

export const useViewVehicle = (listingId: string) => {
  const addViewed = useMemo(() => {
    if (typeof window !== 'undefined') {
      const viewed = localStorage.getItem('viewedVehicles');
      let newViewed = [];
      if (viewed) {
        newViewed = Array.from(new Set([...JSON.parse(viewed), listingId]));
      } else {
        newViewed = [listingId];
      }
      localStorage.setItem('viewedVehicles', JSON.stringify(newViewed));
      return newViewed;
    }
    return [];
  }, [listingId]);

  return { addViewed };
};
export const useIsViewed = (listingId: string) => {
  const isViewed = useMemo(() => {
    if (typeof window === 'undefined') return false;
    const viewed = localStorage.getItem('viewedVehicles');
    if (viewed) {
      return JSON.parse(viewed).includes(listingId);
    }
    return false;
  }, [listingId]);
  return { isViewed };
};
