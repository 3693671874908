import { CarouselDirectionButton } from '@/components/atoms/CarouselButton/CarouselDirectionButton';
import { Direction } from '@/types/enums';
import clsx from 'clsx';
import React, { FC, useCallback, useRef } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { DynamicImageWrapper } from '../DynamicImageWrapper/DynamicImageWrapper';

interface VLPCardImageContainerProps {
  images: string[];
  make: string;
  model: string;
  year: string;
  isMobile: boolean;
  isColumnLayout: boolean;
  handleSaveVehicle: (
    e?:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.TouchEvent<HTMLButtonElement>
  ) => Promise<void>;
  saved: boolean;
  savingVehicle: boolean;
  isViewed?: boolean;
}

export const VLPCardImageContainer: FC<VLPCardImageContainerProps> = ({
  images,
  make,
  model,
  year,
  isMobile,
  isColumnLayout,
  isViewed = false,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const swiperRef = useRef<SwiperClass | null>(null);

  const baseNavClass =
    '!shadow-sm !rounded-r-small !h-s !rounded-l-small border-[1px] !border-neutral-600/40 transition-all duration-250';

  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      if (index === 0) {
        return `<span class="${className} ${baseNavClass} !w-l !bg-neutral-0/90"></span>`;
      }
      return `<span class="${className} ${baseNavClass} !w-s !bg-neutral-300/40"></span>`;
    },
  };

  const handleSlideChange = () => {
    if (swiperRef.current?.el) {
      const activeBullets = swiperRef.current.el.querySelectorAll(
        '.swiper-pagination-bullet-active'
      );
      const allBullets = swiperRef.current.el.querySelectorAll(
        '.swiper-pagination-bullet'
      );

      allBullets.forEach((bullet) => {
        bullet.classList.remove('!w-l', '!bg-neutral-0/90');
        bullet.classList.add('!w-s', '!bg-neutral-300/40');
      });

      activeBullets.forEach((bullet) => {
        bullet.classList.add('!w-l', '!bg-neutral-0/90');
        bullet.classList.remove('!w-s', '!bg-neutral-300/40');
      });
      setActiveIndex(swiperRef.current?.realIndex);
    }
  };
  const handleSlideNext = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  }, []);
  const handleSlidePrev = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  }, []);

  // this will ensure there is a placeholder image if there are no images
  const renderedImages = images.length === 0 ? [''] : images;

  return (
    <div className="relative flex h-full w-full">
      <div
        className={clsx(
          'absolute bottom-0 left-0 z-10 flex rounded-tr-small bg-[#EFEFF1CC]/80 p-m px-s py-xs',
          {
            hidden: !isViewed,
            flex: isViewed,
          }
        )}
      >
        <div className="text-microMedium text-neutral-800">Viewed</div>
      </div>

      <div
        className={`flex h-full w-full items-center ${
          !isMobile && isColumnLayout ? 'rounded-t-lg' : 'rounded-l-lg'
        }`}
      >
        <Swiper
          className="flex h-full w-full"
          pagination={pagination}
          modules={[Pagination]}
          onSlideChange={handleSlideChange}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {renderedImages.map((image, index) => {
            return (
              <SwiperSlide key={`swiper-${image}-${index}`} className="flex">
                <div
                  className={clsx(
                    'flex h-full w-full items-center justify-center'
                  )}
                >
                  <DynamicImageWrapper
                    ariaLabel={`link to build ${year} ${make} ${model}`}
                    make={make}
                    className="!object-cover"
                    src={image}
                    alt={`build ${year} ${make} ${model}`}
                    priority={index === 0}
                    ratio={isColumnLayout ? 0.5 : 0.55}
                  />
                </div>
              </SwiperSlide>
            );
          })}

          <SwiperSlide className="flex flex-grow">
            <div className="relative flex h-full w-full">
              <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-image-blur backdrop-blur-[21px]">
                <div
                  role="button"
                  aria-label="See more"
                  className="flex gap-s rounded-full bg-neutral-800/50 px-xl py-s text-body2Medium text-neutral-0"
                >
                  See more
                </div>
              </div>
              <div className="z-0 flex h-full w-full">
                <DynamicImageWrapper
                  ariaLabel={`link to build ${year} ${make} ${model}`}
                  make={make}
                  className="!object-cover"
                  src={images[0]}
                  alt={`build ${year} ${make} ${model}`}
                  priority={true}
                  ratio={0.5}
                />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div>
          <div
            className={clsx(
              'absolute left-l top-[45%] z-10 hidden -translate-y-1/2 transform transition-opacity duration-150',
              {
                'ml:hidden': activeIndex === 0,
                'hidden opacity-0 group-hover:opacity-100 ml:flex':
                  activeIndex !== 0,
              }
            )}
          >
            <CarouselDirectionButton
              direction={Direction.Left}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleSlidePrev();
              }}
            />
          </div>
          <div
            className={clsx(
              'absolute right-l top-[45%] z-10 hidden -translate-y-1/2 transform transition-opacity duration-150 ml:flex',
              {
                'ml:hidden': activeIndex === 3,
                'hidden opacity-0 group-hover:opacity-100 ml:flex':
                  activeIndex !== 3,
              }
            )}
          >
            <CarouselDirectionButton
              direction={Direction.Right}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleSlideNext();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
